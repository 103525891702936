<script setup lang="ts">
import { PropType } from 'vue'
import { TextSizes } from '~/constants/textSizes'
import { TranslationKey } from '~/i18n/TranslationKeys';
import { SpSvg } from '~~/src/autogen/SpSvg'
import { IconSizes } from '~~/src/constants/iconSizes'

const props = defineProps({
    text: {
        type: String as PropType<TranslationKey>
    },
    inline: { type: Boolean, default: false },
    textSize: { type: String as PropType<TextSizes>, default: TextSizes.BODY_REGULAR },
    hint: { type: String as PropType<TranslationKey> },
    hintReplacements: { type: Object as PropType<string[]>, default: [] },
    hintIcon: { type: String as PropType<SpSvg>, default: SpSvg.BasicHelp }
})
const { text, inline, textSize, hint, hintReplacements, hintIcon } = toRefs(props)
const flexDirection = computed(() =>
    inline.value ? 'flex-row items-baseline' : 'flex-col'
)

const showHint = ref(false)
const updateHint = (status: boolean) => showHint.value = status
const hintAlignment = computed(() => inline.value ? 'items-baseline' : 'items-center')
</script>

<template>
    <div v-if="text != undefined" :class="`flex ${flexDirection} gap-2`">
        <div class="flex flex-row gap-2" :class="hintAlignment">
            <CommonTooltip v-if="hint != undefined" :text="hint" :replacements="hintReplacements" :show="showHint">
                <CommonIcon :icon-name="hintIcon" :icon-size="IconSizes.S" @mouseenter="updateHint(true)"
                    @mouseleave="updateHint(false)" style="transform:translateY(2px)" />
            </CommonTooltip>
            <CommonText class="text-dark-blue-900" :text-size="textSize" :text="text" />
        </div>
        <slot></slot>
    </div>
    <slot v-else></slot>
</template>